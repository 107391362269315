import { CartContext } from "../../CartContext"
import { useContext } from "react"
import { getProductData } from "../../productStore"
import styled from "styled-components";

const CurrentCart = (props) =>{
    const cart = useContext(CartContext);
    const id = props.id;
    const quantity = props.quantity;
    const productData = getProductData(id);

    return(
        <CartContainer>
            <CartItemContainer>
                <CartProductTitle>{productData.title}</CartProductTitle>
                {/* Add this to the Quantity to calculate the price per unit:     "= {(quantity * productData.price).toFixed(2)}"        */}
                <CartProductQuantity>(x{quantity})</CartProductQuantity>
            </CartItemContainer>
            <ButtonContainer>
                <RemoveButton onClick={() => cart.removeOneFromCart(id)}>Remove</RemoveButton>
            </ButtonContainer>
        </CartContainer>
    )
}

const CartContainer = styled.div`
    display: flex;
    margin: 5px 10px;
    border-bottom: 1px solid white;
    color: white;
    @media(max-width: 800px){
       
    }
`

const CartItemContainer = styled.div`
    display: flex;
    @media(max-width: 800px){
        display: inline;
    }
`

const CartProductTitle = styled.h4`
    @media(max-width: 800px){
        font-size: 12px;
        margin-top: 5px;
    }
`

const CartProductQuantity = styled.p`
    margin: 5px;
    margin-left: 15px;
    @media(max-width: 800px){
        margin-left: auto;
    }
`

const ButtonContainer = styled.div`
    margin-left: auto;
    margin-bottom: 5px;
`

const RemoveButton = styled.button`
    width: 80px;
    height: 40px;
    font-size: 14px;
    font-weight: bold;
    background: none;
    color: white;
    border: 1px solid white;
` 

export default CurrentCart