import styled from "styled-components"
import { useState, useContext } from "react";

import DropdownButton from 'react-bootstrap/DropdownButton';
import DropdownItem from "react-bootstrap/esm/DropdownItem";

import emailjs from '@emailjs/browser'

import { productArray } from "../../productStore";
import CurrentCart from "../Cart&Pricing/CurrentCart";
import { CartContext } from "../../CartContext";

const OrderModalTable = ({ getOtherInfo }) =>{

    const cart = useContext(CartContext)
    const [qnty, setQnty] = useState(0)
    const [name, setName] = useState('NAME');
    const [btnTitle, setBtnTitle] = useState("Select a product")
    const [product, setProduct] = useState("Product")

    const productCount = cart.items.reduce((sum, product) => sum + product.quantity, 0);

    const checkout = async (e) => {

        // -------- GET CART ITEMS -------
        let selectedItems = [];
        const items = cart.items;
    
        items.forEach((item) => {
            selectedItems.push({
                name: item.name,
                quantity: item.quantity
            });
        });
    
        // -------- GET USER/OTHER INFO -------
        const otherInfo = getOtherInfo();
    
        
        //  -------- Validate that something has been entered for every field and that the cart has something selected --------

        if (validateOtherInfoEntries(otherInfo) === false) return;
    
        if(!validateItemsInCart()) return

        //Bopth Above returned Successful, proceed

        //  -------- Prepare Email Body --------
        const today = getCurrentDay()
        let emailContent = ''
        
        selectedItems.forEach((item) => {
            emailContent += `+ ${item.name}  (x${item.quantity})\n`;
        });
    

        //  ======= ******** ======= Send the Email ======= ******** =======
        try {
            const response = await emailjs.send(
                'service_1svyf0h', 
                'template_e1ro7if', 
                {
                    userName: otherInfo.Name,
                    orderPlaced: today,
                    phoneNumber: otherInfo.PhoneNumber, 
                    userAddress: `${otherInfo.Address}, ${otherInfo.City}, ${otherInfo.State}, ${otherInfo.Zip}`,
                    needByDate: otherInfo.NeedBy,
                    returnByDate: otherInfo.ReturnBy,
                    orderDetails: emailContent,
                    additionalComments: otherInfo.AdditionalComments

                },
                'm_YkrzH2I1l5wHJMD'
            );
            console.log('Email sent successfully:');
            alert('Your Quote has been sent - Thank you!')
        } catch (error) {
            console.log('Failed to send email:', error.text);
        }
    };



    const validateItemsInCart = () =>{
        if(cart.items.length > 0){
            return true
        }else{
            alert('You must add something to your order before the quote can be sent!')
            return false
        }
    }


    const validateOtherInfoEntries = (otherInfo) =>{
        if(otherInfo.Name){
            if(otherInfo.PhoneNumber){
                if(otherInfo.Address){
                    if(otherInfo.City){
                        if(otherInfo.State){
                            if(otherInfo.Zip){
                                if(otherInfo.NeedBy){
                                    if(otherInfo.ReturnBy){
                                        if(otherInfo.AdditionalComments){

                                            return true

                                        }else{handleMissingItem(); return false}
                                    }else{handleMissingItem(); return false}
                                }else{handleMissingItem(); return false}
                            }else{handleMissingItem(); return false}
                        }else{handleMissingItem(); return false}
                    }else{handleMissingItem(); return false}
                }else{handleMissingItem(); return false}
            }else{handleMissingItem(); return false}   
        }else{handleMissingItem(); return false} 
    }

    const handleMissingItem = () =>{
        alert("Please fill out all fields")
    }

    const getCurrentDay = () =>{
        const thisMonth = new Date().getMonth() + 1;
        const thisDay = new Date().getDate();
        const thisYear = new Date().getFullYear();

        const today = ` ${thisMonth}/${thisDay}/${thisYear}`

        return today
    }

    const addOneQnty = (maxQnty, prodName) =>{
        if(qnty < maxQnty){
            setQnty(qnty+1)
            setName(prodName)
        }else{
            alert("There are only " + maxQnty + " in stock. \n Please give us a call and we will be happy to work with you. We return calls within the same day")
        }
    }
    
    const removeOneQnty = () =>{
        if(qnty > 0){
            setQnty(qnty-1)
            console.log("Removed 1 to Quantity: " + qnty)
        }else{
            alert("Cannot remove any more")
        }
    }

    return(
        <>
        <CartContainer>

            <ItemSelectionExplainationContainer>
                <ItemSelectionExplainationTextContainer>
                    <ItemSelectionText>Select items to be added to your order.</ItemSelectionText>
                </ItemSelectionExplainationTextContainer>
            </ItemSelectionExplainationContainer>
            
            <SelectItemContainer>
                <DropdownContainer>
                    <DropButton id="dropButton" title={btnTitle}>
                        {productArray.map((item) =>(
                            <DropItem id="dropItem" onClick={() => {setProduct(item); setQnty(0); setBtnTitle(item.title)}}>{item.title}</DropItem>
                        ))}
                    </DropButton>
                </DropdownContainer>

                <ChangeQuantityContainer>
                    <ChangeQuantityInnerContainer>
                        <RemoveContainer>
                            <RemoveOneButton onClick={() => removeOneQnty(product.quantity)}>
                                <RemoveQuantityText>-</RemoveQuantityText>
                            </RemoveOneButton>
                        </RemoveContainer>
                        
                        <QuantityContainer>
                            <QuantityInnerContainer>
                                <DisplayQuantityText>{qnty}</DisplayQuantityText>
                            </QuantityInnerContainer>
                        </QuantityContainer>

                        <AddContainer>
                            <AddOneButton onClick={() => addOneQnty(product.quantity, product.title)}>
                                <AddQuantityText>+</AddQuantityText>
                            </AddOneButton>  
                        </AddContainer>
                    </ChangeQuantityInnerContainer>
                </ChangeQuantityContainer>

                    
                <AddToCartContainer>
                    <AddToCart onClick={() => { if(qnty > 0){
                            cart.addMultipleItemsToCart(product.id, qnty, name)
                            }else{alert("Please enter a quantity")}}}>Add
                    </AddToCart>
                </AddToCartContainer>

            </SelectItemContainer>
                <SelectItemBottomContainer>
                    <CurrentCartContianer>
                        {productCount > 0 ?
                        <>
                            {cart.items.map((currentProduct, idx) => (
                                <CurrentCart key={idx} id={currentProduct.id} quantity={currentProduct.quantity}></CurrentCart>
                            ))}
                        </>
                        :
                        <>
                            <NoItemsText>No Items Selected</NoItemsText>
                        </>
                        }
                    </CurrentCartContianer>
                </SelectItemBottomContainer>
            </CartContainer>

            <CheckoutOrderButtonContainer>
                <CheckoutButtonContainer>
                    <CheckoutButton onClick={() => checkout()}>Request Quote</CheckoutButton>
                </CheckoutButtonContainer>
                
                <CancelButtonContainer>
                    <CancelOrderButton onClick={() => cart.emptyCart()}>Cancel</CancelOrderButton>
                </CancelButtonContainer>
                {/* <AffiliateInput placeholder="Affiliate" onChange={(event) => {handleSetAffiliate(event.target.value)}}></AffiliateInput> */}
            </CheckoutOrderButtonContainer>

        </>
    )
}

const CartContainer = styled.div`
    border: 1px solid white;
    margin-top: 10px;
`

// ==== EXPLAINATION TEXT

const ItemSelectionExplainationContainer = styled.div`
    width: 100%;
    margin-top: 10px;
`
const ItemSelectionExplainationTextContainer = styled.div`
    width: 50%;
    margin: auto;
    text-align: center;
`

const ItemSelectionText = styled.p`
    color: white;
`


const SelectItemContainer = styled.div`
    display: flex;
    padding: 10px;
`

// Dropdown Menu
const DropdownContainer = styled.div`
    width: 50%;
    margin: auto;
`

const DropButton = styled(DropdownButton)`
    width: 100%;
    
`

const DropItem = styled(DropdownItem)`
    width: 100%;
`

// Quantity Selector
const ChangeQuantityContainer = styled.div`
    width: 40%;
    margin-right: 5%;
`

const ChangeQuantityInnerContainer = styled.div`
    display: flex;
    width: 60%;
    height: 100%;
    margin-left: auto;
    @media(max-width: 800px){
        width: 100%;
        margin-left: 0;
        margin: auto;
    }
`

// ======== REMOVE BUTTON ========
const RemoveContainer = styled.div`
    width: 40%;
    height: 100%;
    text-align: center;
    @media(max-width: 800px){
        width: 35%;
    }
`

const RemoveOneButton = styled.button`
    border: 0px;
    background: none;
    margin: auto;
`

const RemoveQuantityText = styled.p`
    font-size: 32px;
    color: white;
`

// ======== QUANTITY CONTAINER ========
const QuantityContainer = styled.div`
    width: 20%;
    display: flex; 
    justify-content: center; 
    align-items: center; 
    @media(max-width: 800px){
        width: 30%;
    }
`

const QuantityInnerContainer = styled.div`
    text-align: center;
`

const DisplayQuantityText = styled.p`
    color: white;
    font-size: 24px;
`

// ======== ADD BUTTON ========
const AddContainer = styled.div`
    width: 40%;
    height: 100%;
    text-align: center;
    @media(max-width: 800px){
        width: 35%;
    }
`

const AddOneButton = styled.button`  
    border: 0px;
    background: none;
    margin: auto;
    text-align: center;
`

const AddQuantityText = styled.p`
    font-size: 32px;
    color: white;
`

// Add To Cart Button
const AddToCartContainer = styled.div`
    width: 15%;
    text-align: right;
    @media(max-width: 800px){
        width: 100%;
        height: 100%;
        margin: auto;
        text-align: center;
    }
`

const AddToCart = styled.button`
    height: 100%;
    width: 100%;
    background: transparent;
    background-color: rgba(0,0,0,0.3);
    color: white;
    border: 1px solid white;
    text-align: center;
    &:hover{
        background-color: rgba(0,0,0,0.3)
    };
    @media(max-width: 800px){
        
    }
`

// CART
const SelectItemBottomContainer = styled.div`
    margin-top: 15px;
    border-bottom: 1px solid white;
    padding-bottom: 20px;
    height: 170px;
    display: flex;
    @media(max-width: 900px){
        display: inline;  
    }
`

const CurrentCartContianer = styled.div`
    border: 1px solid white;
    width: 100%;
    height: 100%;
    margin: 10px;
    margin-top: 0px;
    overflow-y: auto;
    text-decoration: none;
    scrollbar-width: none;
    background-color: rgba(0,0,0,0.3);
    @media(max-width: 1300px){
        font-size: 12px;
    }
    @media(max-width: 800px){
        font-size: 12px;
        height: 50%;
        width: 90%;
    }
`

const NoItemsText = styled.text`
    padding: 20px;
    margin: 20px;
    color: white;
`

const CheckoutOrderButtonContainer = styled.div`
    width: 100%;
    margin-top: 20px;
    display: flex;
    @media(max-width: 900px){
        display: flex;
        margin: auto;
        margin-top: 20px;
        width: 300px;
    }
`

const CheckoutButtonContainer = styled.div`
    width: 50%;
    text-align: center;
`

const CheckoutButton = styled.button`
    width: 205px;
    height: 65px;
    background-color: rgba(0,0,0,0.3);
    border: 1px solid white;
    border-radius: 5px;
    color: white;
    @media(max-width: 900px){
        width: 105px;
        height: 50px;
        margin: auto;
    }
`


const CancelButtonContainer = styled.div`
    width: 50%;
    text-align: center;
`

const CancelOrderButton = styled.button`
    width: 205px;
    height: 65px;
    background-color: rgba(0,0,0,0.3);
    border: 1px solid white;
    border-radius: 5px;
    color: white;
    @media(max-width: 900px){
        width: 125px;
        height: 50px;
        margin: auto;
        margin-right: 10px; 
    }
`

export default OrderModalTable;