import { motion, AnimatePresence } from "framer-motion"
import { useEffect, useState } from "react"
import { Button } from "react-bootstrap"

import styled from "styled-components"

import Logo from "../Photos/Logo.png"

import {NavigateNext} from '@styled-icons/material/NavigateNext'
import {NavigateBefore} from '@styled-icons/material/NavigateBefore'

import Slideshow1 from '../Photos/TrailerPottyFull.jpeg'
import Slideshow2 from '../Photos/TrailerPottyInterior.jpeg'
import Slideshow3 from '../Photos/TrailerPottyFloor.jpeg'

const TrailerPottySlideshow = () =>{

    const [currentImage, setCurrentImage] = useState(0)
    const [initialX, setInitialX] = useState(900)

    const slidesArray = [Slideshow1, Slideshow2, Slideshow3]


    const handlePreviousButtonClick = () =>{
        setInitialX(-900)

        if(currentImage === 0){
            setCurrentImage(slidesArray.length - 1)
        }else{
            setCurrentImage(currentImage - 1)
        }
    }

    const handleNextButtonClick = () =>{
        setInitialX(900)
        setCurrentImage(currentImage + 1)

        if(currentImage === slidesArray.length - 1){
            setCurrentImage(0)
        }else{
            setCurrentImage(currentImage + 1)
        }
    }

    return(
        <AnimatePresence>

            <SlideshowContainer>
                {/* The Actual Slideshow (img) */}
                <SlideshowDiv 
                    initial={{x: initialX}} 
                    animate={{x: 0}} 
                    key={`slideshow-slide-#${currentImage}`} 
                    alt={`Slide-#${currentImage}`} 
                    src={slidesArray[currentImage]} 
                />

            </SlideshowContainer>
             <SlideButtonsContainer>
                <PrevButtonContainer>
                    <PrevButtonInnerContainer>
                        <PreviousButton onClick={() => handlePreviousButtonClick()}><PrevIcon /></PreviousButton>
                    </PrevButtonInnerContainer>
                </PrevButtonContainer>
                <NextButtonContainer>
                    <NextButtonInnerContainer>
                        <NextButton onClick={() => handleNextButtonClick()}><NexIcon /></NextButton>
                    </NextButtonInnerContainer>
                </NextButtonContainer>
            </SlideButtonsContainer>
        </AnimatePresence>
    )
}

//Component Container
const SlideshowContainer = styled.div`
    width: 40%;
    height: 100%;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    border-radius: 20px;
    margin: auto;
    box-shadow: 15px 15px 15px rgb(0 0 0 / 0.2);
    @media(max-width: 1300px){
        width: 80%;
    };
`

//Image and it's container

const SlideshowDiv = styled(motion.img)`
    width: 100%;
    border-radius: 20px;
    object-fit: cover;
    z-index: -10;
`

//Buttons
const SlideButtonsContainer = styled.div`
    height: 10%;
    display: flex;
`

const PrevButtonContainer = styled.div`
    width: 50%;
    margin-top: 20px;
`

const PrevButtonInnerContainer = styled.div`
    margin-left: auto;
    width: 50%;
`

const PreviousButton = styled(Button)`
    margin: auto;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    box-shadow: 5px 5px 5px rgb(0 0 0 / 0.2);
    border: 3px solid white;
    padding-left: auto;
`

const PrevIcon = styled(NavigateBefore)`
    width:  40px;
    height: 40px;
`



const NextButtonContainer = styled.div`
    width: 50%;
    text-align: center;
    margin-top: 20px;
`

const NextButtonInnerContainer = styled.div`
    margin-right: auto;
    width: 50%;
`

const NextButton = styled(Button)`
    margin: auto;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    box-shadow: 5px 5px 5px rgb(0 0 0 / 0.2);
    border: 3px solid white;
    margin-left: auto;
`

const NexIcon = styled(NavigateNext)`
    width:  40px;
    height: 40px;
`



export default TrailerPottySlideshow