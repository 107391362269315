import styled from "styled-components"
import { Modal } from 'react-bootstrap'
import {useState} from 'react'

import OrderModalTable from "./OrderSubPages/OrderModalTable";
import OrderModalOtherInfo from "./OrderSubPages/OrderModalOtherInfo";

import "bootstrap/dist/css/bootstrap.css";
import './styles/pricestyle.css'

const OrderButton = () =>{
    
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    
    const handleOpen = () => {
        setShow(true);
    }
    
    //Changed within OtherInfo component
    const [userInfo, setUserInfo] = useState([]);

    //Returns the info that is set with setUserInfo above
    const getOtherInfo = () => {
        return userInfo;
    };


    return(
    <>
        <OrderBtn onClick={handleOpen}>Request a Quote</OrderBtn>
        <OrderModal show={show} onHide={handleClose} contentClassName="modal-height" dialogClassName="modal-width" size="lg" centered>
            
            <Modal.Header closeButton closeVariant="white" id="modalHeader">
                <ModalTitle>
                    <ModalTitleText>Request a Quote</ModalTitleText>
                </ModalTitle>
            </Modal.Header>

            <Modal.Body id="modalBody">
                <OrderModalOtherInfo userInfo={userInfo} setUserInfo={setUserInfo}/>
                <OrderModalTable getOtherInfo={getOtherInfo} />
            </Modal.Body>
        </OrderModal>
    </>
    )
}

const OrderBtn = styled.button`
    width: 225px;
    height: 100px;
    font-size: 24px;
    border-radius: 5px;
    border: 2px solid white;
    background-color: rgba(0,0,0,0.3);
    color: white;
    box-shadow: 5px 5px 5px rgb(0 0 0 / 0.2);
    @media(max-width: 1300px){
        width: 125px;
        height: 75px;
        color: white;
        font-size: 18px;
    }
`

const OrderModal = styled(Modal)`
    overflow: hidden;
`

const ModalTitle = styled(Modal.Title)`
    padding: auto;
`

const ModalTitleText = styled.p`
    text-align: center;
    color: white;
    font-size: 36px;
    @media(max-width: 1300px){
        font-size: 18px;
    }
`

export default OrderButton