import styled from "styled-components"
import { supportedOhioCounties, supportedKentuckyCounties } from "./Coordinates"

import { useState } from "react"

const SupportedCounties = () =>{

    const [ohioCounties, setOhioCounties] = useState(supportedOhioCounties)
    const [kentuckyCounties, setKentuckyCounties] = useState(supportedKentuckyCounties)

    return (
        <SupportedCountiesContainer>
            <SupportedHeaderTextContainer>
              <SupportedCountiesHeaderText>Currently Servicing:</SupportedCountiesHeaderText>
            </SupportedHeaderTextContainer>

            <SupportedCountiesListContainer>
                <OhioCountiesContainer>
                  <OhioCountiesHeader>In Ohio:</OhioCountiesHeader>
                  
                  <OhioCountiesListContainer>
                      {ohioCounties.map((item) =>{
                      return <OhioCountiesList>{item.name} County</OhioCountiesList>  
                      })}  
                  </OhioCountiesListContainer>
                </OhioCountiesContainer>

                <KentuckyCountiesContainer>
                  <KentuckyCountiesHeader>In Kentucky</KentuckyCountiesHeader>
                  
                  <KentuckyCountiesListContainer>
                      {kentuckyCounties.map((item) =>{
                      return <KentuckyCountiesList>{item.name} County</KentuckyCountiesList>  
                      })}
                  </KentuckyCountiesListContainer>
                </KentuckyCountiesContainer>
            </SupportedCountiesListContainer>
      </SupportedCountiesContainer>
    )
}

const SupportedCountiesContainer = styled.div`
  width: 100%;
  height: 100%;
`

const SupportedCountiesListContainer = styled.div`
  width: 100%;  
  height: 90%;
  display: flex;
`

//Header
const SupportedHeaderTextContainer = styled.div`
  height: 10%;
  text-align: center;
`

const SupportedCountiesHeaderText = styled.text`
  color: white;
  font-size: 35px;
  font-weight: 400;
  @media(max-width: 1300px){
    font-size: 25px;
  }
`

//Ohio Counties
const OhioCountiesContainer = styled.div`
  text-align: center;
  width: 50%;
`

const OhioCountiesHeader = styled.text`
  color: white;
  font-size: 45px;
  font-weight: 200;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  @media(max-width: 1300px){
    font-size: 25px;
  }
`

const OhioCountiesListContainer = styled.div`
  width: 75%;
  margin: auto;
  @media(max-width: 1300px){
    width: 50%;
  }
`

const OhioCountiesList = styled.li`
  list-style-type: none;
  font-size: 28px;
  color: white;
  @media(max-width: 1300px){
    font-size: 14px;
  }
`

//Kentucky Counties: 
const KentuckyCountiesContainer = styled.div`
  text-align: center;
  width: 50%;
`

const KentuckyCountiesHeader = styled.text`
  color: white;
  font-size: 45px;
  font-weight: 200;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  @media(max-width: 1300px){
    font-size: 25px;
  }
`

const KentuckyCountiesListContainer = styled.div`
  width: 75%;
  margin: auto;
`

const KentuckyCountiesList = styled.li`
  list-style-type: none;
  font-size: 28px;
  color: white;
  @media(max-width: 1300px){
    font-size: 14px;
  }
`

export default SupportedCounties