import React from 'react'
import { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

import styled, {createGlobalStyle} from "styled-components"

import PricingChart from "./Cart&Pricing/PricingChart"
import ContactPage from './ContactPage'
import ProductStore from '../productStore'
import OrderButton from './Orders'
import Slideshow from './Slideshow'

import Map from './Map/Map'
import { useJsApiLoader } from '@react-google-maps/api'

import SupportedCounties from './CountyCoordinates/SupportedCounties'
import MissionStatement from './MissionStatement'
import TrailerPottySlideshow from './TrailerPottySlideshow'

function Home() {

  const showMaintenanceMessage = false;
  const [showPrices, setShowPrices] = useState(false)
  const [bodyHeight, setBodyHeight] = useState(100)
  
  const libraries = useState(["places"])

  const handleShowPrices = () =>{

    if(!showPrices){
      setBodyHeight('90%')
      setShowPrices(!showPrices)
      console.log('Setting to 60%')
    }else{
      setBodyHeight(100)
      setShowPrices(!showPrices)
      console.log('Setting to 100px')
    }
  }

  const {isLoaded} = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  }) 

  return (
    <>
    <GlobalStyle />
      <AppContainer>

      {/* Outage Div */}
      {showMaintenanceMessage === false ? <></> : 
      <OutageDiv>
        <OutageText>The Site is currently under maintenance and some features may not work</OutageText>
      </OutageDiv>}

      {/* Slideshow */}
      <SlideshowContainer>
        <Slideshow />
      </SlideshowContainer>

      <MissionStatement />

      {/* Map */}
      <OuterMapContainer>
        {!isLoaded ? <div><p>Loading...</p></div> : 
        <InnerMapContainer>

        {/* Counties ountlined on the Map */}
          <MapContainer>
            <Map />
          </MapContainer>

        {/* Supported Counties listed next to the map*/}
          <SupportedCountiesContianer>
            <SupportedCounties />
          </SupportedCountiesContianer>

        </InnerMapContainer>}
      </OuterMapContainer>

          <TrailerPottyContainer>
            <TrailerPottyHeaderContainer>
              <TrailerPottyHeader>COMING SOON: Trailer-Potty Units</TrailerPottyHeader>
              <TrailerPottySlideshow />
            </TrailerPottyHeaderContainer>
          </TrailerPottyContainer>

      {/* Body/Pricing */}
      <AnimatePresence>
        <BodyContainer initial={{height: '100px'}} animate={{height: bodyHeight}} transition={{duration: 0.5}}>

          {/* Buttons */}
            <PricesButtonsContainer>
                <OrderContainer>
                    <OrderButton />
                </OrderContainer>

                <ShowPricesContainer>
                    <ShowPricesButton onClick={() => handleShowPrices()}>Show Prices</ShowPricesButton>
                </ShowPricesContainer>

                {/* <CheckAccountDiv>
                  <CheckAccountButton onClick={() => window.open("https://checkout.wildcatportapotti.com/p/login/9AQ3dLfLU3c4264bII")}>My Orders</CheckAccountButton>
                </CheckAccountDiv> */}
            </PricesButtonsContainer>
          
          {/* Price sheet/chart that drops down */}
            <PricingContainer>
              <ProductStore />
              <AnimatePresence>
                {showPrices &&  
                  <PricingChart/>
                }
              </AnimatePresence>
            </PricingContainer>
        </BodyContainer>

      </AnimatePresence>

      <AnimatePresence>
      <ContactContainer>
        <ContactPage/>
      </ContactContainer>
      </AnimatePresence>

      <FooterContainer>
      <CopyrightContainer>
        <CopyrightText>Copyright ©2023 Wildcat Porta-Potti</CopyrightText>
      </CopyrightContainer>
      </FooterContainer>

      </AppContainer>

    </>
  );
}

const GlobalStyle = createGlobalStyle`
  body {
    overflow-x: hidden;
    max-width: 100%;
    width: 100%;
  }
`

const AppContainer = styled.div`
  height: 100vh;
  overflow: scroll;
  position: relative;
  background-color: #2b6cd4;
  background-image: -webkit-linear-gradient(40deg, #013A6B 50%, #004E95 50%);
`

//Header

const OutageDiv = styled.div`
  margin-bottom: 20px;
  text-align: center;
  color: white;
  height: 40px;
`

const OutageText = styled.p`
  font-size: 24px;
  @media(max-width: 800px){
    font-size: 18px;
    height: 55px;
  }
  @media(max-width: 500px){
    font-size: 12px;
    height: 55px;
  }
`

//Body
const BodyContainer = styled(motion.div)`
  margin-top: 10%;
`

const SlideshowContainer = styled.div`
  width: 95vw;
  height: 60%;
  margin: auto;
  margin-bottom: 100px;
  @media(max-width: 800px){
    font-size: 18px;
    height: 40%;
  }
`


//Buttons to show/hide and order units
const PricesButtonsContainer = styled.div`
  display: flex;
  width: 100%; 
  margin: auto;  
  margin-bottom: 2%; 
`

const ShowPricesContainer = styled.div`
  margin: auto;
  width: 33.33%;
  text-align: center;
`

const ShowPricesButton = styled.button`    
  width: 225px;
  height: 100px;
  font-size: 24px;
  border-radius: 5px;
  border: 2px solid white;
  background-color: rgba(0,0,0,0.3);
  color: white;
  box-shadow: 5px 5px 5px rgb(0 0 0 / 0.2);
  @media(max-width: 1300px){
    width: 125px;
    height: 75px;
    color: white;
    font-size: 18px;
  }
`

const OrderContainer = styled.div`
  margin: auto;
  text-align: right;
  width: 33.33%;
`

// const CheckAccountDiv = styled.div`
//   margin: auto;
//   width: 33.33%;
// `

// const CheckAccountButton = styled.button`
//   width: 225px;
//   height: 100px;
//   font-size: 24px;
//   border-radius: 5px;
//   border: 2px solid white;
//   background-color: rgba(0,0,0,0.3);
//   color: white;
//   box-shadow: 5px 5px 5px rgb(0 0 0 / 0.2);
//   @media(max-width: 1300px){
//     width: 125px;
//     height: 75px;
//     color: white;
//     font-size: 18px;
//   }
// `

const PricingContainer = styled.div`
  height: 300px;
  margin: 25px;
`

//Map
const OuterMapContainer = styled.div`
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  margin-top: 10%;
  padding: 10px 0;
`

const InnerMapContainer = styled.div`
  height: 60vh;
  display: flex;
  @media(max-width: 800px){
    display: inline;
  }
`

const MapContainer = styled.div`
  width: 60vw;
  margin-left: 10%;
  @media(max-width: 1300px){
    width: 80vw;
    height: 40vh;
    margin: auto;
  }
`


const TrailerPottyContainer = styled.div`
  text-align: center;
`
const TrailerPottyHeaderContainer = styled.div`
  margin-top: 10px;
  color: white;
`
const TrailerPottyHeader = styled.p`

`


//Supported Counties
const SupportedCountiesContianer = styled.div`
  width: 100vw;
  overflow-y: auto;
  @media(max-width: 1300px){
    
  }
`

const ContactContainer = styled(motion.div)`
  margin: 25px;
  margin-top: 10%;
`

//Footer
const FooterContainer = styled(motion.div)`

`

const CopyrightContainer = styled.div`
  text-align: center;
  margin-bottom: 25px;
`

const CopyrightText = styled.text`
  color: white;
  font-size: 24px;
`


export default Home;
