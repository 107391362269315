import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import Home from './Components/Home';
import AdminPage from './Components/AdminPage';

function App(){

    return(
    <Router>
        <Routes>
            <Route exact path="/" element={<Home />}></Route>
            <Route exact path="/admin" element={<AdminPage />} />
        </Routes>
    </Router>    
    )
}

export default App;