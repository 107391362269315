import { useEffect, useState } from "react";
import styled from "styled-components"

import { withGoogleMap, GoogleMap, Polygon } from "react-google-maps"
//Custom Counties
import { 
    BooneCounty,
    BrownCounty, 
    ButlerCounty, 
    CampbellCounty,
    ClermontCounty, 
    ClintonCounty,
    HamiltonCounty, 
    HighlandCounty,
    KentonCounty, 
    WarrenCounty 
} from "../CountyCoordinates/Coordinates";

const Map = () =>{

    const defaultCenter = {lat: 39.29, lng: -83.98}
    const [zoom, setZoom] = useState(0);

    const handleHover = (e) =>{
        console.log(e.latLng.lat() + "," + e.latLng.lng())
    }

    useEffect(() =>{

        //Run this immediately, then listen for any resizes, so the zoom can be reset when the screen size is changed.
        const setCustomZoom = () =>{
            if(window.innerWidth <= 1300){
                setZoom(7)
            }else{setZoom(8)}
        }
        setCustomZoom();

        window.addEventListener('resize', setCustomZoom);
        //Dump the Listener when done!
        return () => {
            window.removeEventListener('resize', setCustomZoom);
        };
    }, [])

    const CountyMap = withGoogleMap(() => (
        <CoverageCountyMap 
            center={defaultCenter}
            defaultZoom={zoom}
            onClick={(e) =>{handleHover(e)}} 
        >
            <Polygon
                editable={false}
                draggable={false}
                paths={WarrenCounty}
                options={{
                    fillColor: '#2b6cd4',
                    fillOpacity: 0.4,
                    strokeColor: 'black',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    clickable: false,
                    draggable: false,
                    editable: false
                }}
            />

            <Polygon
                editable={false}
                draggable={false}
                paths={KentonCounty}
                options={{
                    fillColor: '#2b6cd4',
                    fillOpacity: 0.4,
                    strokeColor: 'black',
                    strokeOpacity: 0.8,
                    clickable: false,
                    draggable: false,
                    editable: false
                }}
            />

            <Polygon
                editable={false}
                draggable={false}
                paths={BrownCounty}
                options={{
                    fillColor: '#2b6cd4',
                    fillOpacity: 0.4,
                    strokeColor: 'black',
                    strokeOpacity: 0.8,
                    clickable: false,
                    draggable: false,
                    editable: false
                }}
            />

            <Polygon
                editable={false}
                draggable={false}
                paths={ClermontCounty}
                options={{
                    fillColor: '#2b6cd4',
                    fillOpacity: 0.4,
                    strokeColor: 'black',
                    strokeOpacity: 0.8,
                    clickable: false,
                    draggable: false,
                    editable: false
                }}
            />

            <Polygon
                editable={false}
                draggable={false}
                paths={ClintonCounty}
                options={{
                    fillColor: '#2b6cd4',
                    fillOpacity: 0.4,
                    strokeColor: 'black',
                    strokeOpacity: 0.8,
                    clickable: false,
                    draggable: false,
                    editable: false
                }}
            />

            <Polygon
                editable={false}
                draggable={false}
                paths={BooneCounty}
                options={{
                    fillColor: '#2b6cd4',
                    fillOpacity: 0.4,
                    strokeColor: 'black',
                    strokeOpacity: 0.8,
                    clickable: false,
                    draggable: false,
                    editable: false
                }}
            />

            <Polygon
                editable={false}
                draggable={false}
                paths={ButlerCounty}
                options={{
                    fillColor: '#2b6cd4',
                    fillOpacity: 0.4,
                    strokeColor: 'black',
                    strokeOpacity: 0.8,
                    clickable: false,
                    draggable: false,
                    editable: false
                }}
            />

            <Polygon
                editable={false}
                draggable={false}
                paths={HamiltonCounty}
                options={{
                    fillColor: '#2b6cd4',
                    fillOpacity: 0.4,
                    strokeColor: 'black',
                    strokeOpacity: 0.8,
                    clickable: false,
                    draggable: false,
                    editable: false
                }}
            />

            <Polygon
                editable={false}
                draggable={false}
                paths={HighlandCounty}
                options={{
                    fillColor: '#2b6cd4',
                    fillOpacity: 0.4,
                    strokeColor: 'black',
                    strokeOpacity: 0.8,
                    clickable: false,
                    draggable: false,
                    editable: false
                }}
            />

            <Polygon
                editable={false}
                draggable={false}
                paths={CampbellCounty}
                options={{
                    fillColor: '#2b6cd4',
                    fillOpacity: 0.4,
                    strokeColor: 'black',
                    strokeOpacity: 0.8,
                    clickable: false,
                    draggable: false,
                    editable: false
                }}
            />

        </CoverageCountyMap>
    ));

    return( 
        <MapDiv onContextMenu={(e)=>e.preventDefault()}>
            <CountyMap
                containerElement={ <CoverageContainerElement/> }
                mapElement={ <CoverageMapElement />}
                draggable={false}
            />
        </MapDiv>
    )
}

const CoverageCountyMap = styled(GoogleMap)`
    
`

const CoverageContainerElement = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
`

const CoverageMapElement = styled.div`
    height: 95%;
    width: 95%;
    margin: auto;
`

const MapDiv = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    border: 1px solid white;
    border-radius: 5px;
    box-shadow: 5px 5px 5px rgb(0 0 0 / 0.2);
`

export default Map