import styled from "styled-components";
import 'react-dropdown/style.css';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const OrderModalOtherInfo = ({ userInfo, setUserInfo }) =>{

    // const handleAddAffiliate = () =>{
    //     if(affiliate !== null){
    //         const newTotal = 0;
    //         const currentAffiliate = db
    //         .collection("affiliates")
    //         .where('code', '==', affiliate)
    //         .get()
    //         .then((querySnapshot) =>{
    //             querySnapshot.docs.forEach(doc => {
    //                 doc.ref.update({
    //                     month: doc.data().month+=1,
    //                     total: doc.data().total+=1
    //                 })
    //             })
    //         })
    //     }
    // }

    const handleChange = (field, value) => {
        setUserInfo((prev) => ({
            ...prev,
            [field]: value
        }));
    };

    // const handleSetAffiliate = (affiliate) =>{setAffiliate(affiliate)

    const today = getCurrentDay()

    return(
        <>
            <UserInfoContainer>
                <PersonalInfoContainer>
                    <NameContainer>
                        {/* <Name type="text" placeholder="TEST" value={userInfo.testItem} onChange={(event) => handleChange({field: 'testItem', value: event.target.value})}/> */}
                        <Name type="text" placeholder="Name" value={userInfo.name} onChange={(event) => handleChange("Name", event.target.value)}/>
                    </NameContainer>
                    
                    <PhoneNumberContainer>
                        <PhoneNumber type="number" placeholder="Phone Number" value={userInfo.number} onChange={(event) => handleChange("PhoneNumber", event.target.value)}/>
                    </PhoneNumberContainer>
                </PersonalInfoContainer>
                
                <AddressContainer>
                    <Address type="text" placeholder="Address" value={userInfo.address} onChange={(event) => handleChange("Address", event.target.value)}/>
                </AddressContainer>

                <AddressLineTwoContainer>
                    <CityContainer>
                        <City type="text" placeholder="City" value={userInfo.city} onChange={(event) => handleChange("City", event.target.value)}/>
                    </CityContainer>
                    <StateZipContainer>
                        
                        <StateContainer>
                            <SelectOptionSelect id="stateDropdownButton" defaultValue={"defaultState"} onChange={(event) => handleChange("State", event.target.value)}>
                                <SelectOption value="defaultState" disabled hidden>State</SelectOption>
                                <SelectOption value="Ohio">Ohio</SelectOption>
                                <SelectOption value="Kentucky">Kentucky</SelectOption>
                            </SelectOptionSelect>
                        </StateContainer>

                        <ZipContainer>
                            <Zip type="number" placeholder="Zip" value={userInfo.Zip} onChange={(event) => handleChange("Zip", event.target.value)}/>
                        </ZipContainer>
                    </StateZipContainer>
                </AddressLineTwoContainer>

                <DateSelectionContainer>
                    <StartDateContainer>
                        <StartLabelContainer>
                            <StartLabel>Need By</StartLabel>
                        </StartLabelContainer>
                        <StartDatePickerContainer>
                            <StartDatePicker value={userInfo.NeedBy ? userInfo.NeedBy : today} onChange={(date) => {
                                const formattedSelection = formatDate(date)
                                handleChange("NeedBy", formattedSelection)
                            }} />
                        </StartDatePickerContainer>
                    </StartDateContainer>

                    <PlaceholderContainer />

                    <ReturnDateContainer>
                        <ReturnLabelContainer>
                            <ReturnLabel>Return By</ReturnLabel>
                        </ReturnLabelContainer>
                        <ReturnDatePickerContainer>
                            <ReturnDatePicker value={userInfo.ReturnBy ? userInfo.ReturnBy : today} onChange={(date) => {
                                const formattedSelection = formatDate(date)
                                handleChange("ReturnBy", formattedSelection)}
                            }/>
                        </ReturnDatePickerContainer>
                    </ReturnDateContainer>
                </DateSelectionContainer>
            </UserInfoContainer>

            <AdditionalCommentsContainer>
                <AdditionalCommentsTextContainer>
                    <AdditionalCommentsText>Leave any additional comments, notes or requests:</AdditionalCommentsText>
                </AdditionalCommentsTextContainer>
                <AdditionalCommentsInputContainer>
                    <AdditionalCommentsInput placeholder="Additional Comments" onChange={(event) => handleChange("AdditionalComments", event.target.value)}/>
                </AdditionalCommentsInputContainer>
            </AdditionalCommentsContainer>
        </>
    )
}



const getCurrentDay = () =>{
    const thisMonth = new Date().getMonth() + 1;
    const thisDay = new Date().getDate();
    const thisYear = new Date().getFullYear();

    const today = ` ${thisMonth}/${thisDay}/${thisYear}`

    return today
}

function formatDate(date) {
    const newDate = new Date(date);
    const month = String(newDate.getMonth() + 1).padStart(2, '0');
    const day = String(newDate.getDate()).padStart(2, '0');
    const year = newDate.getFullYear();
    return `${month}/${day}/${year}`;
  }



// Name, Number 
const UserInfoContainer = styled.div`
    padding: 10px;
    border: 1px solid white;
    margin-top: 10px;
    hiehgt: 60%
    @media(max-width: 900px){
        
    }
`
const PersonalInfoContainer = styled.div`
    display: flex;
    height: 50px;
    @media(max-width: 900px){
        display: inline;
    }
`

const NameContainer = styled.div`
    border: 1px solid white;
    width: 100%;
`

const Name = styled.input`
    width: 350px;
    height: 50px;
    border: none;
    background: none;
    border-bottom: 1px solid white;
    background-color: #013A6B;
    padding: 15px;
    font-size: 18px;
    color: white;
    &::placeholder{
        color: white;
    }
    padding-top: 10px;
    &:focus{
        outline: none
    }
`

const PhoneNumberContainer = styled.div`
    border: 1px solid white;
    width: 350px;
    margin-left: 40px;
    background-color: #013A6B;
    border: 1px solid white;
    @media(max-width: 900px){
        margin-left: 0px;
        margin-top: 15px;
        width: 100%;
    }
`

const PhoneNumber = styled.input`
    width: 350px;
    height: 50px;
    border: none;
    background: none;
    padding: 15px;
    font-size: 18px;
    color: white;
    &::placeholder{
        color: white;
    }
    padding-top: 10px;
    &:focus{
        outline: none
    }
`

/* Address  */
const AddressContainer = styled.div`
    border: 1px solid white;
    background-color: #013A6B;
    width: 100%;
    height: 50px;
    margin-top: 20px;
`

/* City, State, Zip */

const AddressLineTwoContainer = styled.div`
    display: flex;
    @media(max-width: 900px){
        display: inline;
    }
`

const Address = styled.input`
    height: 50px;
    width: 100%;
    border: none;
    background: none;
    padding: 15px;
    font-size: 18px;
    color: white;
    &::placeholder{
        color: white;
    }
    padding-top: 10px;
    &:focus{
        outline: none
    }
`

const CityContainer = styled.div`
    border: 1px solid white;
    background-color: #013A6B;
    width: 300px;
    height: 50px;
    margin-top: 20px;
    margin-right: 25px;
    @media(max-width: 900px){
        width: 100%;   
    }
`
const City = styled.input`
    width: 300px;    
    height: 50px;
    border: none;
    background: none;
    padding: 15px;
    font-size: 18px;
    color: white;
    &::placeholder{
        color: white;
    }
    padding-top: 10px;
    &:focus{
        outline: none
    }
`

const StateZipContainer = styled.div`
    display: flex;
    margin: auto;
    @media(max-width: 900px){
        width: 100%;  
    }
`
const ZipContainer = styled.div`
    border: 1px solid white;
    width: 50%;
    height: 50px;
    margin-top: 20px;
    background-color: #013A6B;
    @media(max-width: 900px){
        width: 175px;  
    }
`
const Zip = styled.input`
    width: 100%;
    height: 50px;
    border: none;
    background: none;
    padding: 15px;
    font-size: 18px;
    color: white;
    &::placeholder{
        color: white;
    }
    padding-top: 10px;
    &:focus{
        outline: none
    }
`


// ======= STATE SELECTOR =======
const StateContainer = styled.div`
    border: 1px solid white;
    background-color: #013A6B;
    width: 50%;
    height: 50px;
    margin-top: 20px;
    margin-right: 25px;
    @media(max-width: 900px){
        margin-right: 15px;
    }
`

const SelectOptionSelect = styled.select`
    width: 100%;
    height: 100%;
    background-color: #013A6B;
    color: white;
    border: none;
    outline: none;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    appearance: none; /* Removes default dropdown arrow */
    padding: 10px;

    &:focus {
        outline: none;
        background-color: #013A6B; /* Ensures no highlight effect */
    }
`;

const SelectOption = styled.option`
    background-color: #013A6B;
    color: white;
    font-size: 16px;
    border: none;
    
    &:hover, &:focus, &:active {
        background-color: #013A6B; /* Prevents highlighting */
        color: white;
    }
`;



// ======= Date Selector =======

const DateSelectionContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding: 5px;
    @media(max-width: 900px){
        
    }
`

// ======= START DATE =======
const StartDateContainer = styled.div`
    width: 50%;   
    height: 50px; 
    display: flex;
    font-size: 18px;
    margin-left: auto;
    border: 1px solid white;
    background-color:  #013A6B;
    @media(max-width: 900px){
        margin-left: 0px;
    }
`

const StartLabelContainer = styled.div`
    margin: auto;
    height: 50%;
    width: 50%;
    padding-left: 10%;
    @media(max-width: 900px){
        padding-left: 10px;
        width: 50%
    }
`

const StartLabel = styled.p`
    color: white;
    @media(max-width: 900px){
        padding: 0;
        font-size: 14px;
    }
`

const StartDatePickerContainer = styled.div`
    width: 40%;
    height: 70%;
    margin: auto;
    @media(max-width: 900px){
        width: 60%;
        padding-right: 10px;
    }
`

const StartDatePicker = styled(DatePicker)`
    background-color: #013A6B;
    color: white;
    border: 1px solid white;
    padding: 5px 10px;
    width: 100%;
    height: 35px;
    outline: none;
    text-align: center;
    @media(max-width: 900px){
        padding: 0px;
        font-size: 14px;
    }
`

// ======= Placeholder =======
const PlaceholderContainer = styled.div`
    width: 10%;
    @media(max-width: 900px){
        width: 10px;
    }
`

// ======= RETURN DATE =======
const ReturnDateContainer = styled.div`
    width: 50%;   
    height: 50px; 
    display: flex;
    font-size: 18px;
    margin-right: auto;
    border: 1px solid white;
    background-color: #013A6B;
    @media(max-width: 900px){
        margin-left: 0px;
    }
`

const ReturnLabelContainer = styled.div`
    margin: auto;
    height: 50%;
    width: 50%;
    padding-left: 10%;
    @media(max-width: 900px){
        padding-left: 10px;
        width: 50%
    }
`

const ReturnLabel = styled.p`
    color: white;
    padding-right: 10px;
    @media(max-width: 900px){
        padding: 0;
        font-size: 14px;
    }
`

const ReturnDatePickerContainer = styled.div`
    width: 40%;
    height: 70%;
    margin: auto;
    @media(max-width: 900px){
        width: 60%;
        padding-right: 10px;
    }
`

const ReturnDatePicker = styled(DatePicker)`
    background-color: #013A6B;
    color: white;
    border: 1px solid white;
    padding: 5px 10px;
    width: 100%;
    height: 35px;
    outline: none;
    text-align: center;
    @media(max-width: 900px){
        padding: 0px;
        font-size: 14px;
    }
`

// ======= Additional Comments =========

const AdditionalCommentsContainer = styled.div`
    margin-top: 10px;
    border: 1px solid white;
    height: 170px;
`
const AdditionalCommentsTextContainer = styled.div`
    text-align: center;
`
const AdditionalCommentsText = styled.p`
    margin-top: 10px;
    color: white;
`

const AdditionalCommentsInputContainer = styled.div`    
    width: 85%;
    margin: auto;
    height: 100%;
`

const AdditionalCommentsInput = styled.textarea`
    width: 100%;
    height: 60%;
    background: none;
    color: white;
    border: 1px solid white;
    background-color: #013A6B;
    padding: 10px;
    resize: none; 
    outline: none; 
    
    &::placeholder {
        color: white;   
    } 
`


export default OrderModalOtherInfo;